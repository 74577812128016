<script lang="ts" setup>
import { useGoogleMaps } from '../composables/useGoogleMaps'

const props = defineProps<{
  handleInput?: (event: Event) => void
  ui?: Record<string, unknown>
}>()

const isError = defineModel<boolean>('error')

const model = defineModel<{
  address: string | null
  city: string | null
  region: string | null
  country: string | null
  postalCode: string | null
  streetNumber: string | null
  route: string | null
  coordinates: { lat: number, lng: number } | null | undefined
  name: string | null | undefined
}>({ required: true, default: { address: '' } })

const { t } = useI18n()

const inputRef = ref<{ input: HTMLInputElement } | null>(null)
const autocomplete = ref<google.maps.places.SearchBox>()
const addressRef = ref(model.value.address || '')

const google = useGoogleMaps()

function getAddressComponent(
  components: google.maps.GeocoderAddressComponent[],
  type: string,
) {
  const component = components.find(component =>
    component.types.includes(type),
  )

  return component?.short_name || null
}

watchEffect(() => {
  if (!inputRef.value) {
    return
  }

  if (!google.value) {
    return
  }

  if (autocomplete.value) {
    return
  }

  autocomplete.value = new google.value.maps.places.SearchBox(inputRef.value.input)

  autocomplete.value.addListener('places_changed', () => {
    if (!autocomplete.value) {
      return
    }

    const places = autocomplete.value.getPlaces()

    if (!places || places.length === 0) {
      return
    }

    const place = places[0]

    if (!place.address_components) {
      return
    }

    const keysMap = {
      city: 'locality',
      region: 'administrative_area_level_1',
      country: 'country',
      postalCode: 'postal_code',
      streetNumber: 'street_number',
      route: 'route',
    } as const

    const address = place.formatted_address || place.name || getAddressComponent(place.address_components, 'route') || ''

    const coordinates = {
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    }

    model.value.address = address
    addressRef.value = address

    if (place.name && place.business_status && !model.value.name) {
      model.value.name = place.name
    }

    Object.entries(keysMap).forEach(([key, type]) => {
      const value = getAddressComponent(place.address_components!, type)
      if (value) {
        model.value[key as keyof typeof keysMap] = value
      }
    })

    if (typeof coordinates.lat === 'number' && typeof coordinates.lng === 'number') {
      model.value.coordinates = coordinates as { lat: number, lng: number }
    }
  })
})

function blur(event: Event) {
  const target = event.target as HTMLInputElement
  if (target.value !== model.value.address || !model.value.address) {
    isError.value = true
  } else {
    isError.value = false
  }
}

watch(() => model.value.address, () => {
  isError.value = false
})

function handleInput(e: Event) {
  const target = e.target as HTMLInputElement
  if (target.value === model.value.address) {
    isError.value = false
  }
  props.handleInput?.(e)
}
</script>

<template>
  <UInput
    id="autocomplete"
    ref="inputRef"
    v-model="addressRef"
    :placeholder="t('dashboard.index.createRestaurantModal.fields.address.placeholder')"
    icon="i-tabler-map-pin"
    :color="isError ? 'red' : undefined"
    :ui="props.ui"
    @input="handleInput"
    @blur="blur"
  />
</template>
